
.search-page{
    background-color: #ffffff;
    .div{
        height: 50px;
    }
    .history-list{
        padding: 15px;
        .history-search{
            font-size: 13px;
            line-height: 18px;
            color: #666666;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .icon-delete{
                width: 16px;
                height: 16px;
                background: url(https://file.huatu.com/static/miniprogram/shop/h5/home/icon_delete.png), center no-repeat;
                background-size: 100% 100%;
            }
        }
        .histort-item{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            .item{
                background: #F5F6F8;
                border-radius: 2px;
                line-height: 28px;
                font-size: 14px;
                color: #333333;
                text-align: center;
                padding: 0 12px;
                margin: 12px 12px 0 0;
            }
        }
    }
    /deep/.van-search__content{
        border-radius: 16px;
    }
}
